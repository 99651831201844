import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { Logout } from "../redux/toolkit/auth/operation";
import Swal from "sweetalert2";
import { useIdleTimer } from "react-idle-timer";

const PrivateRoute = ({ redirectTo = "/" }: any) => {
  const navigate = useNavigate();
  const token: any = localStorage.getItem("token");
  const dispatch = useDispatch<AppDispatch>();
  const userLogout: any = useSelector((state: RootState) => state.qrDetails);
  let checkOnce = false;

  axios.interceptors.response.use(
    function (response: any) {
      return Promise.resolve(response);
    },
    function (error: any) {
      if (error.response.status === 401 || error.response.status === 440) {
        dispatch(Logout(token));
        if (error.response.status === 440 && checkOnce === false) {
          Swal.fire({
            icon: "error",
            title: "Session Expired",
            text: "Please Log In Again",
          });
          checkOnce = true;
        }
      } else {
        return Promise.reject(error);
      }
    }
  );

  const onIdle = () => {
    dispatch(Logout(token)).then(()=> {
      Swal.fire({
        icon: "error",
        title: "Session Expired",
        confirmButtonColor: "#86B6EF",
        text: "Please Log In Again",
      });
      localStorage.clear();
      navigate("/");
    })
   
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 300000, // for testing purpose of this functionality you can change the timeout to a lower one
    throttle: 500
  })

  useEffect(() => {
    console.log(userLogout)
    if (userLogout.data === "") {
      localStorage.clear();
      navigate("/");
    }
  }, [navigate, userLogout]);

  const isLoggedIn = localStorage.getItem("token");

  return isLoggedIn ? <Outlet /> : <Navigate to={redirectTo} />;
};

export default PrivateRoute;
