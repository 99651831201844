export const PathStrConst = {
    signin: '/',
    signup: '/registration',
    forgotPassword: '/forgotPassword',
    resetPassword: '/resetPassword/:token',
    verification: '/verification',
    dashboard: '/dashboard',
    users: '/users',
    home: '/home',
    accounts: '/accounts',
    role: '/roles',
    permission: '/permissions',
    merchants:'/merchants',
    idleUsers: "/idleUsers"
}

