import { BrowserRouter, Routes, Route } from "react-router-dom";
import { publicRoutes, privateR } from "./routes";
import PrivateRoute from "./routes/privateRoute";
import PublicRoute from "./routes/publicRoute";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";
// const roles: string[] = JSON.parse(localStorage.getItem('roles') || "[]");


function App() {
  const getRoles = (state: RootState) => {
  const qrDetailssData: any = state.qrDetails
    const rolesData = qrDetailssData.data?.data?.user?.roles;
    return rolesData ? rolesData.map((r: any) => r.slug) : JSON.parse(localStorage.getItem("roles") || "[]");
  };
  const roles = useSelector(getRoles);
  return (
    <BrowserRouter>
      <Routes>
        {publicRoutes.map((value) => (
          <Route path={value.path} element={<PublicRoute />} key={""}>
            <Route path={value.path} element={value.component} />
          </Route>
        ))}
        {privateR.map((value) => {

          if (roles?.length && value?.roles?.some(item => roles?.includes(item))) 
          return <Route path={value.path} element={<PrivateRoute />} key={""}>
            <Route path={value.path} element={value.component} />
          </Route>
        })}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
