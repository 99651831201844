import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import axios from "axios";
import BASE_URL from "../../constants/baseURL";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import DefaultSidebar from "../../components/sidebar";
import { CircularProgress, Grid, Pagination, TextField } from "@mui/material";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import { Container } from "react-bootstrap";
import Header from "../../components/common/header";
import { Table, TableBody, TableCell, TableHead, TableRow, Button } from "@mui/material";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ProgressBar from "../../components/common/progressbar";
import { Typography } from "@mui/material";
import Swal from "sweetalert2";

// Define types for User and Account
interface Account {
    b4bUUID: string;
    accountName: string;
    IBAN: string;
    currency: string;
    lastTransactionDate: string;
}

interface User {
    email: string;
    phone: string;
    name: string;
    companyName: string;
    createdAt: string;
    lastLogin: string;
    accounts: Account[];
}

const IdleUsers = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const userLogout: any = useSelector((state: RootState) => state.qrDetails);
    const [loading, setLoading] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(true);
    const [churningUsersList, setChurningUsersList] = useState<any>([]);
    const [progress, setProgress] = useState(0);
    const [idleDays, setIdleDays] = useState<number>(14);
    const Token: any = localStorage.getItem('token');
    const [uploadingCsv, setUploadingCsv] = useState<boolean>(false); 

    // Columns for Main Table
    const dataColumns: MUIDataTableColumnDef[] = [
        { name: "companyRegNumber", label: "Business registration number", },
        { name: "email", label: "Email registered" },
        { name: "phone", label: "Phone No registered" },
        { name: "firstName", label: "First Name registered" },
        { name: "lastName", label: "Last Name registered" },
        { name: "companyName", label: "Company Name registered" },
        { name: "companyAddress", label: "Street Address registered",
            options: {
                customBodyRender: (value) => {
                    return (
                        <div style={{ width: "200px", wordWrap: "break-word", whiteSpace: "normal" }}>
                            {value}
                        </div>
                    );
                }
            }
         },
        { name: "postalCode", label: "Postal Code registered" },
        { name: "city", label: "City registered" },
        { name: "country", label: "Country registered" },
        { name: "createdAt", label: "Date of registration" },
        { name: "lastLogin", label: "Last Login",
            options: {
                customBodyRender: (value) => {
                    return (
                        <div style={{ width: "150px", wordWrap: "break-word", whiteSpace: "normal" }}>
                            {value}
                        </div>
                    );
                }
            }
         },
        {
            name: "description", 
            label: "Last Transaction Date",
            options: {
                customBodyRender: (value) => {
                    return (
                        <div style={{ width: "300px", wordWrap: "break-word", whiteSpace: "normal" }}>
                            {value}
                        </div>
                    );
                }
            }
        },
        // {
        //     name: "actions",
        //     label: "Actions",
        //     options: {
        //         customBodyRender: (_value, tableMeta) => {
        //             const rowIndex = tableMeta.rowIndex;
        //             return (
        //                 <Button
        //                     variant="contained"
        //                     color="primary"
        //                     onClick={() => handleSendEmail(churningUsersList[rowIndex])}
        //                 >
        //                     <ForwardToInboxIcon />
        //                 </Button>
        //             );
        //         },
        //         filter: false,
        //         sort: false,
        //     },
        // },
    ];


    const options: MUIDataTableOptions = {
        expandableRows: true,
        expandableRowsHeader: false,
        responsive: "standard",
        selectableRows: "none",
        renderExpandableRow: (rowData, rowMeta) => {
            const rowIndex = rowMeta.dataIndex;
            const accounts = churningUsersList[rowIndex].accounts;

            return (
                <TableRow>
                    {/* Use colSpan to span across all columns */}
                    <TableCell colSpan={dataColumns.length} style={{ padding: "16px" }}>
                        <Table size="small" aria-label="expandable-table" style={{ width: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>B4B UUID</TableCell>
                                    <TableCell>Account Name</TableCell>
                                    <TableCell>IBAN</TableCell>
                                    <TableCell>Currency</TableCell>
                                    <TableCell>Last Transaction Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {accounts.map((account: Account, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell>{account.b4bUUID}</TableCell>
                                        <TableCell>{account.accountName}</TableCell>
                                        <TableCell>{account.IBAN}</TableCell>
                                        <TableCell>{account.currency}</TableCell>
                                        <TableCell>{account.lastTransactionDate}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableCell>
                </TableRow>
            );
        },
        customToolbar: () => {
            return (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUploadCsv}
                    style={{ textTransform: "capitalize", position: "relative" }}
                    disabled={uploadingCsv} // Disable button while uploading
                >
                    {uploadingCsv ? (
                        <Spinner
                            animation="border"
                            size="sm"
                            style={{ marginRight: "10px" }}
                        />
                    ) : null}
                    Upload CSV
                </Button>
            );
        }
    };

    // Handle idle days input change
    const handleIdleDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === "") {
            setIdleDays(0);
            return;
        }
        if (parseInt(value, 10) <= 365) {
            setIdleDays(parseInt(value, 10));
        } else {
            alert("Idle days cannot be more than 365.");
        }
    };

    // Handle submit of idle days
    const handleIdleDaysSubmit = () => {
        if (idleDays === 0) {
            alert("Please enter a valid number for Idle Days before submitting.");
            return;
        }
        getChurningUsers(idleDays);
    };

    // api request
    async function getChurningUsers(days: number) {
        setLoadingUsers(true);
        setInitialLoading(true);
        setProgress(0);
        const updateProgress = (event: any) => {
            if (event.total) {
                setProgress(Math.round((event.loaded / event.total) * 100));
            }
        };
        try {
            const response: any = await axios.get(`${BASE_URL}payment/getChurningUsers`, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                params: {
                    days: idleDays,
                },
                onDownloadProgress: updateProgress,
                onUploadProgress: updateProgress,
            });
            setChurningUsersList(response.data.data);
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            setProgress(100);
            setLoadingUsers(false);
            setInitialLoading(false);
        }
    }

    // API request to Upload CSV
    const handleUploadCsv = async () => {
        setUploadingCsv(true); 
        try {
            const response = await axios.get(`${BASE_URL}payment/getChurningUsersForCsv`, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
                params: {
                    days: idleDays,
                },
            });

            if (response.data.success) {
                Swal.fire({
                    icon: "success",
                    title: "File Uploaded",
                    text: "File has been Uploaded successfully",
                });
            }

        } catch (error) {
            console.error("Error uploading CSV:", error);
            Swal.fire("Error", "Failed to upload CSV.", "error");
        } finally {
            setUploadingCsv(false);
        }
    };

    useEffect(() => {
        if (userLogout.data === "") {
            localStorage.clear();
            navigate("/");
        }
    }, [userLogout]);

    useEffect(() => {
        getChurningUsers(idleDays);
    }, [])

    return (
        <Container fluid className="App">
            <Row>
                <Col
                    style={{ backgroundColor: "white", minHeight: "", color: "white" }}
                    lg={2}
                >
                    <DefaultSidebar />
                </Col>
                <Col
                    style={{ backgroundColor: "#ececec" }}
                    sm={12}
                    xl={10}
                    xs={12}
                    md={12}
                    lg={10}
                >
                    <Header />
                    {/* Add Input and Button */}
                    <Grid container spacing={2} style={{ marginBottom: "20px", marginTop: "10px" }}>
                        <Grid item xs={8} sm={4} md={2}>
                            <TextField
                                label="Idle Days"
                                variant="outlined"
                                fullWidth
                                value={idleDays === 0 ? "" : idleDays}
                                onChange={handleIdleDaysChange}
                                inputProps={{ min: 1, max: 365, type: "number" }}
                            />
                        </Grid>
                        <Grid item xs={4} sm={3} md={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleIdleDaysSubmit}
                                style={{ height: "100%", fontSize: "20px", letterSpacing: "3px", textTransform: "capitalize" }}
                            >
                                Find
                            </Button>
                        </Grid>
                    </Grid>
                    {initialLoading || loadingUsers ? (
                        <div
                            className="d-flex justify-content-between gap-2  align-items-center"
                            style={{ alignItems: "flex-start" }}
                        >
                            <ProgressBar progress={progress} />
                            <Typography>{progress}%</Typography>
                        </div>
                    ) : !loadingUsers && churningUsersList.length === 0 ? (
                        <h3>Oops, something went wrong</h3>
                    ) : (
                        <div style={{ margin: 10 }}>
                            <MUIDataTable
                                title={"Idle Users List"}
                                data={churningUsersList}
                                columns={dataColumns}
                                options={options}
                            />
                            {loading && <CircularProgress />}
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default IdleUsers;
