import React from "react";
import { PathStrConst } from "./strconstpath";
import SignIn from "../signIn";
import Verification from "../verfication";
import Dashboard from "../dashboard";
import Home from "../pages/home";
import Accounts from "../accounts";
import Roles from "../roles";
import Permissions from '../permissions'
import Merchants from "../pages/merchants";
import { staticRoles } from "../utils/constants";
import IdleUsers from "../pages/idleUsers";
const {SUPER_ADMIN,ADMIN_USER,ACCOUNTANT_USER} = staticRoles

export const publicRoutes = [
  { path: PathStrConst.signin, component: <SignIn /> },
  { path: PathStrConst.verification, component: <Verification /> },
];

export const privateR = [
  { path: PathStrConst.users, component: <Dashboard />,        roles:[SUPER_ADMIN, ADMIN_USER, ACCOUNTANT_USER] },
  { path: PathStrConst.home, component: <Home />,              roles:[SUPER_ADMIN, ADMIN_USER, ACCOUNTANT_USER ] },
  { path: PathStrConst.accounts, component: <Accounts />,      roles:[SUPER_ADMIN, ADMIN_USER, ACCOUNTANT_USER ] },
  { path: PathStrConst.role, component: <Roles />,             roles:[SUPER_ADMIN ] },
  { path: PathStrConst.permission, component: <Permissions />, roles:[SUPER_ADMIN ] },
  { path: PathStrConst.merchants, component: <Merchants />,    roles:[SUPER_ADMIN ] },
  { path: PathStrConst.idleUsers, component: <IdleUsers />,    roles:[SUPER_ADMIN, ADMIN_USER ] },
];
