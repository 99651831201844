import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGO_URL } from "../constants/stringConstants";
import InputComponent from "../components/input/index";
import { RootState, AppDispatch } from "../redux/store";
import { Logout, QrVerification } from "../redux/toolkit/auth/operation";
import "./index.css";
import { PathStrConst } from "../routes/strconstpath";
import { staticRoles } from "../utils/constants";
import Swal from "sweetalert2";
const {SUPER_ADMIN,ADMIN_USER,ACCOUNTANT_USER} =  staticRoles

const Verfication = () => {
  const [token, setToken] = useState("");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [userPhone] = useState(location.state.data.phoneNo);
  const [userEmail] = useState(location.state.data.email);
  const [adminAccess, setAdminAccess] = useState(false)
  const navigate = useNavigate();

  const qrDetailss: any = useSelector((state: RootState) => state.qrDetails);

  const dispatch = useDispatch<AppDispatch>();
  const handleButtonClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setLoading(true);
  
    const reqPayload = {
      phoneNo: userPhone,
      email: userEmail,
      token: token,
    };
  
    try {
      const { payload }: any = await dispatch(QrVerification(reqPayload));
      const userRoles = payload.data.user?.roles || [];
      const hasAccess = userRoles.some((role: any) =>
        [SUPER_ADMIN, ADMIN_USER, ACCOUNTANT_USER].includes(role.slug)
      );
  
      setAdminAccess(hasAccess);
  
      if (!hasAccess) {
        await Swal.fire({
          icon: "error",
          title: "Access Denied",
          text: "You do not have permission to access this application.",
        });
        await dispatch(Logout(payload.data.accessToken));
        localStorage.clear();
        navigate("/");
      }
    } catch (error) {
      console.error("Error during verification:", error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    if (qrDetailss && Object.keys(qrDetailss.data).length !== 0) {
      const token = qrDetailss.data.data.accessToken;
      localStorage.setItem("token", token);
      const userId = qrDetailss.data?.data.user?._id;
      localStorage.setItem("userId", userId);
      const firstName = qrDetailss.data?.data.user.firstName;
      localStorage.setItem("firstName", firstName);
      const lastName = qrDetailss.data?.data.user.lastName;
      localStorage.setItem("lastName", lastName);
      //Adding roles
      const rolesSlug = qrDetailss?.data?.data?.user?.roles?.map((el: any) => el?.slug) || [];
      localStorage.setItem("roles", JSON.stringify(rolesSlug));

      if (localStorage.getItem("token") && adminAccess) {
        navigate(PathStrConst.home, { state: qrDetailss.data });
      }
    }
  }, [qrDetailss,adminAccess]);
  return (
    <div className="Verification">
      <div className="left">
        <div className="logo-box">
          <div className="logo-wrapper">
            <img
              className="logo"
              style={{ width: 208, height: 80 }}
              src={LOGO_URL}
              alt="One Money Way"
            ></img>
          </div>
        </div>
        <div className="left-title-box">
          <h2 className="left-title">The Payment </h2>
          <h2 className="left-title-second">Platform</h2>
          <h2 className="left-title-third">for SMEs</h2>
        </div>
        <div className="welcome-box">
          <p className="welcome">Welcome</p>
          <p>Just a couple of clicks and we begin</p>
        </div>
        <div className="circle-box">
          <div className="blue-circle"></div>
          <div className="white-circle"></div>
        </div>
      </div>
      <div className="ver-right">
        <div className="point-box">
          <p className="left-point"></p>
          <p className="middle-point"></p>
          <p className="right-point"></p>
        </div>
        <h2 className="ver-right-title">Identity verification</h2>
        <p className="enter-text">
          Enter the verification code from Authenticator
        </p>
        {location.state.data.qr ? <img src={location.state.data.qr} /> : " "}
        <form className="verification-form">
          <InputComponent
            className="enter-inp"
            placeholder="Enter code"
            onChange={(e) => setToken(e.target.value)}
          />
          <button className="send-btn sign-btn" onClick={handleButtonClick}>
            {loading ? <Spinner animation={"border"} /> : "Send"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Verfication;
