import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Navbar,
  Modal,
  Button,
  Form,
  Container,
} from "react-bootstrap";
import DefaultSidebar from "../components/sidebar";
import { Power } from "react-bootstrap-icons";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import BASE_URL from "../constants/baseURL";
import { Chip } from "@mui/material";
import CustomToolbar from "../components/customtoolbar/customtoolbar";
import Header from "../components/common/header";
import ProgressBar from "../components/common/progressbar";
import { Typography } from "@mui/material";
import { staticRoles } from "../utils/constants";
const {SUPER_ADMIN} = staticRoles

const Permissions = () => {
  const [permissions, setPermissions] = useState([]);
  const [showUpdateInfo, setShowUpdateInfo] = useState(false);
  const [uPermission, setUPermission] = useState({
    _id: "",
    name: "",
    slug: "",
  });
  const [updateObj, setUpdateObj] = useState({
    name: "",
    slug: "",
  });
  const Token: any = localStorage.getItem("token");
  const firstName: any = localStorage.getItem("firstName");
  const lastName: any = localStorage.getItem("lastName");
  const [isCreate, setIsCreate] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [progress, setProgress] = useState(0);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const roles: string[] = JSON.parse(localStorage.getItem('roles') || "[]");


  const options = {
    customToolbar: () => {
      return (
        <CustomToolbar
          handleCloseUpdateInfo={handleCloseUpdateInfo}
          setIsCreate={setIsCreate}
        />
      );
    },
  };

  const Headers = [
    { name: "name", label: "Name" },
    { name: "slug", label: "Slug" },
    {
      name: "Update Info",
      label: "Update Info",
      options: {
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <>
              <Chip
                color="info"
                disabled={!roles.some(role => [SUPER_ADMIN].includes(role))}
                onClick={() => {
                  handleShowUserInfoUpdate(permissions[dataIndex]);
                  setUPermission(permissions[dataIndex]);
                }}
                label="Update"
              />
            </>
          );
        },
      },
    },
  ];

  const handleCloseUpdateInfo = () => {
    if (showUpdateInfo === true) {
      setUpdateObj({
        name: "",
        slug: "",
      });
    }
    setShowUpdateInfo(!showUpdateInfo);
  };

  const onChangeText = (key: string, e: any) => {
    const obj = {
      ...updateObj,
      [key]: e.target.value,
    };
    setUpdateObj(obj);
  };

  const getPermissions = async () => {
    setLoadingUsers(true);
    setInitialLoading(true);
    setProgress(0);

    const updateProgress = (event: any) => {
      if (event.total) {
        setProgress(Math.round((event.loaded / event.total) * 100));
      }
    };
    try {
      let res = await axios.get(`${BASE_URL}permission`, {
        onDownloadProgress: updateProgress,
        onUploadProgress: updateProgress,
      });
      if (res?.status === 200 && res?.data?.data) setPermissions(res.data.data);
    } catch (error) {
      console.log("Error ", error);
    } finally {
      setProgress(100);
      setLoadingUsers(false);
      setInitialLoading(false);
    }
  };

  const createPermission = async ({}) => {
    try {
      let obj = {
        name: updateObj?.name,
        slug: updateObj?.slug,
      };

      let res = await axios.post(`${BASE_URL}permission`, obj);
      if (res) {
        setIsCreate(false);
        getPermissions();
        handleCloseUpdateInfo();
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const updatePermission = async ({}) => {
    try {
      let obj = {
        name: updateObj?.name,
        slug: updateObj?.slug,
      };
      let res = await axios.put(
        `${BASE_URL}permission/${uPermission?._id}`,
        obj
      );
      if (res) {
        getPermissions();
        handleCloseUpdateInfo();
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const handleShowUserInfoUpdate = (el: any) => {
    if (el) {
      const obj = {
        name: el?.name,
        slug: el?.slug,
      };
      setUpdateObj(obj);
    }
    handleCloseUpdateInfo();
  };

  useEffect(() => {
    getPermissions();
  }, []);

  return (
    <Container fluid className="App">
      <Row>
        <Col
          style={{
            backgroundColor: "white",
            minHeight: "",
            color: "white",
          }}
          lg={2}
        >
          <DefaultSidebar />
        </Col>

        <Col
          style={{
            backgroundColor: "#ececec",
          }}
          sm={12}
          xl={10}
          xs={12}
          md={12}
          lg={10}
        >
          <Header />

          {initialLoading || loadingUsers ? (
            <div
              className="d-flex justify-content-between gap-2  align-items-center"
              style={{ alignItems: "flex-start" }}
            >
              <ProgressBar progress={progress} />
              <Typography>{progress}%</Typography>
            </div>
          ) : !loadingUsers && permissions.length === 0 ? (
            <h3>Oops, something went wrong</h3>
          ) : (
            <div style={{ margin: 10 }}>
              <MUIDataTable
                title={"Permissions List"}
                data={permissions}
                columns={Headers}
                options={options}
              />
            </div>
          )}
        </Col>

        <Modal show={showUpdateInfo} onHide={handleCloseUpdateInfo}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isCreate ? "Create Permission" : "Update Permission"}
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={() => {}}>
            <Modal.Body>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  onChange={(e) => onChangeText("name", e)}
                  required={true}
                  defaultValue={updateObj?.name}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="slug">
                <Form.Label>Slug Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Slug"
                  onChange={(e) => onChangeText("slug", e)}
                  required={true}
                  defaultValue={updateObj?.slug}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleCloseUpdateInfo}
                type="button"
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() =>
                  isCreate ? createPermission({}) : updatePermission({})
                }
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </Row>
    </Container>
  );
};

export default Permissions;
