import React, { useEffect, useState } from "react";
import { Row, Col, Navbar, Dropdown, TabContainer } from "react-bootstrap";
import axios from "axios";
import BASE_URL from "../../constants/baseURL";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  Logout,
  FetchLatestTransactions,
  DeleteMerchant,
  EditMerchant,
} from "../../redux/toolkit/auth/operation";
import { useNavigate } from "react-router-dom";
import DefaultSidebar from "../../components/sidebar";
import { CircularProgress } from "@mui/material";
import MUIDataTable, { MUIDataTableColumn } from "mui-datatables";
import { Button, Container, Form, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import Header from "../../components/common/header";
import ProgressBar from "../../components/common/progressbar";
import { Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const Merchants = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [merchantList, setMerchantList] = useState<Merchant[]>([]);
  const [userList, setUserList] = useState([]);
  const userLogout: any = useSelector((state: RootState) => state.qrDetails);
  const [loading, setLoading] = useState(false);
  const [showAddMerchantForm, setShowAddMerchantForm] = useState(false);
  const [merchantId, setMerchantId] = useState('');
  const [merchantName, setmerchantName] = useState('');
  const [userIdToAddMerchant, setuserIdToAddMerchant] = useState('');
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [progress, setProgress] = useState(0);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  
  const [showEditMerchantForm, setShowEditMerchantForm] = useState(false);
  const [merchantIdToEdit, setMerchantIdToEdit] = useState('');
  const [merchantNameToEdit, setMerchantNameToEdit] = useState('');

  const Token: any = localStorage.getItem('token');
  interface Merchant {
    _id: string;
    merchantId: string;
    merchantName: string;
    userId: string;
    dataFetchedUntil: string;
    hasMerchantTransaction: boolean;
  }

  const merchantsHeader: MUIDataTableColumn[] = [
    { name: "_id", label: "ID", options: { display: "excluded" } },
    { name: "merchantId", label: "Merchant Id" },
    { name: "userId", label: "User Id" },
    { name: "dataFetchedUntil", label: "Data Fetched Until" },
  ];

  const usersHeader: MUIDataTableColumn[] = [
    { name: "email", label: "Email" },
    { name: "phoneNo", label: "Phone No" },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (_, tableMeta) => {
          const userId = tableMeta.rowData[3];

          return (
            <Button onClick={() => handleButtonClick(userId)}>
              Add Merchant
            </Button>
          );
        },
      },
    },
    { name: "_id", label: "_id", options: { display: "excluded" } },
  ];

  async function fetchAllMerchantsAPI() {
    try {
      const response: any = await axios.get(
        `${BASE_URL}shift4cards/getMerchants`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      setMerchantList(response.data.data);
    } catch (error) {
      console.error("Error fetching merchants:", error);
    }
  }

  async function addMerchant(merchantId: any, userId: any, merchantName: any) {
    try {
      const response: any = await axios.post(`${BASE_URL}shift4cards/addMerchant`, {
        merchantId: merchantId,
        userId: userId,
        merchantName: merchantName
      }, {
        headers: {
          "Authorization": `Bearer ${Token}`
        }
      });

      return response;
    } catch (error:any) {
      console.error("Error fetching merchants:", error);
      return error.response;

    }
  }

  async function getAllUsers() {
    setLoadingUsers(true);
    setInitialLoading(true);
    setProgress(0);
    const updateProgress = (event: any) => {
      if (event.total) {
        setProgress(Math.round((event.loaded / event.total) * 100));
      }
    };
    try {
      const response: any = await axios.get(`${BASE_URL}user/getAllUsers`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        onDownloadProgress: updateProgress,
        onUploadProgress: updateProgress,
      });
      setUserList(response.data.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setProgress(100);
      setLoadingUsers(false);
      setInitialLoading(false);
    }
  }

  const handleFetchData = async (merchantId: string, merchantName: string) => {
    const payload = {
      merchantId: merchantId,
      merchantName: merchantName
    };

    let buttonElement;
    try {
      buttonElement = document.getElementById(`fetchButton-${merchantId}`);
      if (buttonElement) {
        buttonElement.innerHTML = "<span>Loading...</span>";
        buttonElement.setAttribute("disabled", "true");
      }

      const response: any = await dispatch(FetchLatestTransactions(payload));

      if (response.payload.data === "success") {
        Swal.fire("Success", "Data has been fetched.", "success").then(() => {
          // Update the specific merchant's row data for real-time UI update
          setMerchantList((prev) =>
            prev.map((merchant) =>
              merchant.merchantId === merchantId
                ? { ...merchant, dataFetchedUntil: new Date().toISOString() }
                : merchant
            )
          );
        });
      } else {
        Swal.fire("Error", "Failed to fetch data.", "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      Swal.fire("Error", "Failed to fetch data.", "error");
    } finally {
      if (buttonElement) {
        buttonElement.innerHTML = "Fetch Data";
        buttonElement.removeAttribute("disabled");
      }
    }
  };

  const handleDeleteData = async (merchantId: string, merchantName: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Do you really want to delete ${merchantName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    }).then( async (result)=> {
      if (result.isConfirmed) {
        const payload = {
          merchantId: merchantId,
          merchantName: merchantName
        };
    
        const response: any = await dispatch(DeleteMerchant(payload));
    
        if (response.payload.data.message === "Deleted Successfully") {
          Swal.fire("Success", "Merchant deleted successfully!", "success").then(() => {
            // Remove the deleted merchant from the state
            setMerchantList((prev) => prev.filter((merchant) => merchant.merchantId !== merchantId));
          });
        } else {
          Swal.fire("Error", "Failed to delete merchant.", "error");
        }
      }
    });
  };

  const handleCloseAddMerchantForm = () => setShowAddMerchantForm(false);
  const handleSubmitMerchantForm = async () => {
    const response: any = await addMerchant(merchantId, userIdToAddMerchant, merchantName);
    setShowAddMerchantForm(false);
    if (response?.data?.data.message === "success") {
      Swal.fire("Success", "Merchant added successfully", "success").then(() => {
        // Add the newly added merchant to the state
        fetchAllMerchantsAPI();
      });
    } else {
      Swal.fire("Error", response?.data?.data.message || "Error while adding merchant.", "error");
    }

    setMerchantId('');
    setmerchantName('');
  }

  const handleButtonClick = (userId: any) => {
    setShowAddMerchantForm(true);
    setuserIdToAddMerchant(userId);
  };

  // Function to open Edit Modal
  const handleOpenEditMerchantForm = (merchantId: string, merchantName: string) => {
    setMerchantIdToEdit(merchantId);
    setMerchantNameToEdit(merchantName);
    setShowEditMerchantForm(true);
  };

  // Handle submit for Edit Merchant
  const handleSubmitEditMerchantForm = async() => {
    const payload = {
      merchantId: merchantIdToEdit,
      merchantName: merchantNameToEdit
    };

    const response: any = await dispatch(EditMerchant(payload));
    if (response.payload.data.message === "Edited Successfully") {
      Swal.fire("Success", "Merchant name updated successfully!", "success").then(() => {
        // Update the edited merchant's data in the state
        setMerchantList((prev) =>
          prev.map((merchant) =>
            merchant.merchantId === merchantIdToEdit
              ? { ...merchant, merchantName: merchantNameToEdit }
              : merchant
          )
        );
      });
    } else {
      Swal.fire("Error", "Failed to update merchant.", "error");
    }
    setShowEditMerchantForm(false);
  };

  useEffect(() => {
    fetchAllMerchantsAPI();
    getAllUsers();
  }, []);

  useEffect(() => {
    if (userLogout.data === "") {
      localStorage.clear();
      navigate("/");
    }
  }, [userLogout]);

  const renderExpandableRow = (rowData: any, rowMeta: any) => {
    const userId = rowData[3];
    const userMerchants = merchantList.filter(
      (merchant) => merchant.userId === userId
    );

    if (userMerchants.length === 0) {
      return null;
    }

    return (
      <div style={{ width: "250px" }}>
        <table
          style={{
            width: "100%",
            marginBottom: "20px",
            borderCollapse: "collapse",
          }}
        >
          <thead style={{ width: "250px" }}>
            <tr>
              <th
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "center",
                  width: "250px",
                  whiteSpace: "nowrap"
                }}
              >
                Merchant Id
              </th>
              <th
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "center",
                  width: "250px",
                  whiteSpace: "nowrap"
                }}
              >
                Merchant Name
              </th>
              <th
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "center",
                  width: "250px",
                  whiteSpace: "nowrap",
                }}
              >
                Data Fetched Until
              </th>

              <th
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "center",
                  width: "250px",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody style={{ width: "250px" }}>
            {userMerchants.map((merchant) => (
              <tr key={merchant._id}>
                <td
                  style={{
                    borderBottom: "1px solid #ddd",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {merchant.merchantId}
                </td>
                <td
                  style={{
                    borderBottom: "1px solid #ddd",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {merchant.merchantName}
                </td>
                <td
                  style={{
                    borderBottom: "1px solid #ddd",
                    padding: "8px",
                    width: "100px",
                    marginLeft: "50px",
                    textAlign: "center",
                  }}
                >
                  {merchant.dataFetchedUntil
                    ? merchant.dataFetchedUntil.split("T")[0]
                    : ""}
                </td>

                <td style={{ borderBottom: '1px solid #ddd', padding: '8px', width: '20px' }}>
                  <Button
                    id={`fetchButton-${merchant.merchantId}`}
                    onClick={() => handleFetchData(merchant.merchantId, merchant.merchantName)}
                    disabled={isFetchButtonDisabled(merchant.dataFetchedUntil, merchant.hasMerchantTransaction)}
                    style={{ width: '101px',whiteSpace:"nowrap" }}
                  >
                    Fetch Data
                  </Button>
                </td>
                <td style={{ borderBottom: '1px solid #ddd', padding: '8px', width: '20px' }}>
                  <Button
                    onClick={() => handleOpenEditMerchantForm(merchant.merchantId, merchant.merchantName)}
                    style={{ backgroundColor: 'green', border: '1px solid #ddd' }}
                  >
                    <EditIcon />
                  </Button>
                </td>
                <td style={{ borderBottom: '1px solid #ddd', padding: '8px', width: '20px' }}>
                  <Button
                    onClick={() => handleDeleteData(merchant.merchantId, merchant.merchantName)}
                    style={{ backgroundColor: 'red', border: '1px solid #ddd' }}
                  >
                    <DeleteIcon />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const isFetchButtonDisabled = (dataFetchedUntil: any, hasMerchantTransaction: boolean) => {
    if (!hasMerchantTransaction) return true;
    if (dataFetchedUntil === undefined) {
      return false;
    }

    const today = new Date().toISOString().split("T")[0];
    const extractedDate = dataFetchedUntil.split("T")[0];
    return extractedDate === today;
  };

  return (
    <Container fluid className="App">
      <Row>
        <Col
          style={{ backgroundColor: "white", minHeight: "", color: "white" }}
          lg={2}
        >
          <DefaultSidebar />
        </Col>
        <Col
          style={{ backgroundColor: "#ececec" }}
          sm={12}
          xl={10}
          xs={12}
          md={12}
          lg={10}
        >
          <Header />
          {initialLoading || loadingUsers ? (
            <div
              className="d-flex justify-content-between gap-2  align-items-center"
              style={{ alignItems: "flex-start" }}
            >
              <ProgressBar progress={progress} />
              <Typography>{progress}%</Typography>
            </div>
          ) : !loadingUsers && userList.length === 0 ? (
            <h3>Oops, something went wrong</h3>
          ) : (
            <div style={{ margin: 10 }}>
              <MUIDataTable
                title={"Merchants List"}
                data={userList}
                columns={usersHeader}
                options={{
                  filter: true,
                  filterType: "dropdown",
                  responsive: "standard",
                  expandableRows: true,
                  expandableRowsHeader: false,
                  expandableRowsOnClick: true,
                  isRowExpandable: (dataIndex, expandedRows) => {
                    return true;
                  },
                  renderExpandableRow: renderExpandableRow,
                }}
              />
              {loading && <CircularProgress />}
            </div>
          )}

          <Modal show={showAddMerchantForm} onHide={handleCloseAddMerchantForm}>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: 15, textAlign: 'center', alignContent: 'center' }}>Add Merchant</Modal.Title>
            </Modal.Header>
            <Form>
              <Modal.Body>
                <Form.Group className="mb-3" controlId="MerchantID">
                  <Form.Label>Merchant Id</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Merchant Id"
                    value={merchantId}
                    onChange={(e) => setMerchantId(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="Password">
                  <Form.Label>Merchant Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Merchant Name"
                    value={merchantName}
                    onChange={(e) => setmerchantName(e.target.value)}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAddMerchantForm} type="button">
                  Close
                </Button>
                <Button variant="primary" onClick={handleSubmitMerchantForm}>
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          {/* Edit Merchant Modal */}
          <Modal show={showEditMerchantForm} onHide={() => setShowEditMerchantForm(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Merchant</Modal.Title>
            </Modal.Header>
            <Form>
              <Modal.Body>
                <Form.Group className="mb-3" controlId="EditMerchantName">
                  <Form.Label>Merchant Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter new merchant name"
                    value={merchantNameToEdit}
                    onChange={(e) => setMerchantNameToEdit(e.target.value)}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowEditMerchantForm(false)}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSubmitEditMerchantForm}>
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default Merchants;
