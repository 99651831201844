import React from "react";
import "./index.css";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import { LOGO_URL } from "../../constants/stringConstants";
import { PathStrConst } from "../../routes/strconstpath";
import { staticRoles } from "../../utils/constants";
const {SUPER_ADMIN,ADMIN_USER,ACCOUNTANT_USER} = staticRoles

const DefaultSidebar = (props: any) => {
  const roles: string[] = JSON.parse(localStorage.getItem('roles') || "[]");

  const linkData = [
    { path: PathStrConst.home,       label: "Home",        disabled: false , roles:[ SUPER_ADMIN,ADMIN_USER, ACCOUNTANT_USER ] },
    { path: PathStrConst.accounts,   label: "Accounts",    disabled: false , roles:[ SUPER_ADMIN,ADMIN_USER, ACCOUNTANT_USER ] },
    { path: PathStrConst.role,       label: "Roles",       disabled: false , roles:[ SUPER_ADMIN ] },
    { path: PathStrConst.permission, label: "Permissions", disabled: false , roles:[ SUPER_ADMIN ] },
    { path: PathStrConst.users,      label: "Users",       disabled: false , roles:[ SUPER_ADMIN, ADMIN_USER, ACCOUNTANT_USER ] },
    { path: PathStrConst.merchants,  label: "Merchants",   disabled: false , roles:[ SUPER_ADMIN ] },
    { path: PathStrConst.idleUsers,  label: "Idle Users",   disabled: false , roles:[ SUPER_ADMIN, ADMIN_USER ] },
    { path: "/",                     label: "Settings",    disabled: true ,  roles:[ SUPER_ADMIN,ADMIN_USER ] },
    { path: "/",                     label: "Subscription",disabled: true ,  roles:[ SUPER_ADMIN,ADMIN_USER ] },
  ];

  return (
    <Navbar sticky="top" className="navbar-main min-vh-100-lg flex-lg-column flex-sm-row" expand="lg">
      <LinkContainer to={PathStrConst.home}>
        <img className="width" src={LOGO_URL} alt="logo for Array" />
      </LinkContainer>

      <Navbar.Toggle className="navbartogglers" aria-controls="responsive-navbar-nav" />

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="d-flex flex-column pt-4">
          {linkData.map((link, index) =>  {
            if (roles?.length && link?.roles.some(item => roles?.includes(item))){
            return <LinkContainer key={index} to={link.path} activeClassName="activeClass">
                <Nav.Link className={`NavLink ${link.disabled ? 'disabled-link' : 'active-link'}`} onClick={link.disabled ? (e) => e.preventDefault() : undefined}>
                  {link.label}
                </Nav.Link>
              </LinkContainer>
             }
          }
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default DefaultSidebar;
